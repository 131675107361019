<template>
    <div class="max-w-full overflow-x-auto nice-scrollbar">
        <table class="w-full border-t border-l border-gray-200">
            <thead>
                <tr class="border-b border-r border-gray-200 whitespace-nowrap">
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.reference') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.title') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.description') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.defaultQuantity') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.price') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.purchase_price') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.unity') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.category') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.vat_number') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="product in props.products"
                    :key="product.reference"
                    class="border-b border-r border-gray-200"
                >
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.reference ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.title ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.description ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.default_quantity ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.price ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.purchase_price ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.price_type ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.category ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.vat ?? '-' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts" setup>
    import { RawPreviewProduct } from '../../../app/common/classes/ProductPreview';
    const props = defineProps({
  products: null
});
</script>
