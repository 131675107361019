<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const { tenant, authed } = storeToRefs(useAuthStore());
    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const loading = ref(true);
    const success = ref(false);
    const sending = ref(false);
    const error = ref(false);
    const form = useForm<{
        to: string;
        cc: string;
        copy: boolean;
        subject: string;
        text: string;
    }>({
        to: '',
        cc: '',
        copy: false,
        subject: '',
        text: '',
    });

    useModal(
        'invoicing:invoice:send-email',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            form.set('to', invoice.value.contact?.email);
            retrieveForm();

            if (invoice.value.status === 'draft') {
                useEvent('invoicing:invoice:mark-as-sent:open', {
                    invoice: invoice.value,
                });
            }
        },
        () => {
            form.reset();
            error.value = false;
            success.value = false;
            sending.value = false;
        }
    );

    const retrieveForm = async () => {
        loading.value = true;
        const formData = await endpoint.notifyForm(invoice.value!.getId(), 'email');
        form.set('subject', formData.subject);
        form.set('text', formData.text);
        form.set('copy', formData.copy);
        loading.value = false;
    };

    const displayableTo = computed(() => {
        return form
            .get('to')
            .split(',')
            .map((email) => email.trim())
            .join(', ');
    });

    const submit = async () => {
        sending.value = true;

        let cc = form
            .get('cc')
            .split(',')
            .map((email: string) => email.trim());
        if (cc === '' || cc[0] === '') {
            cc = null;
        }

        let to = form.get('to');
        if (to === '') {
            to = null;
        }

        const response = await form.loadUntil(
            endpoint.notify(invoice.value!.getId(), 'email', {
                subject: form.get('subject'),
                text: form.get('text'),
                copy: !!form.get('copy'),
                to,
                cc,
            }),
            2000
        );
        const { $mixpanel } = useNuxtApp();

        $mixpanel.track(`invoicing_invoices_${invoice.value?.documentType}_sent`, {
            'Driver Type': 'email',
        });

        sending.value = false;

        if (!response.error) {
            useEvent('invoicing:invoice:notified', 'email');
            success.value = true;
        } else {
            error.value = true;
        }
    };
    const expectedTestModeEmail = computed(() => tenant.value?.contactInformations.email || authed.value?.email);
    const isEmailValid = computed(() => {
        if (tenant.value?.isNotInTestMode) return true;
        return form.fields.to === expectedTestModeEmail.value;
    });
    const isEmailInvalid = computed(() => !isEmailValid.value);
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:send-email"
        scrollable
    >
        <div
            v-if="tenant?.isInTestMode"
            class="flex items-center gap-3 p-3 mt-8 ml-8 mr-16 text-white bg-orange-600 rounded"
        >
            <i class="text-3xl fa-light fa-triangle-exclamation"></i>
            <span class="text-sm">
                {{ $t('invoicing.invoice.send_modal.test_mode.alert.text') }}
                (
                <CopyToClipboard :value="expectedTestModeEmail || ''" />)
            </span>
        </div>
        <div
            class="relative"
            :class="{ 'min-h-[500px]': invoice.status == 'draft' }"
        >
            <div
                v-if="invoice.status == 'draft'"
                class="absolute inset-0 z-20 bg-white rounded-lg"
            >
                <div :class="$theme('modal.padding', 'h-full w-full')">
                    <ModalLottie
                        json="https://assets3.lottiefiles.com/packages/lf20_d97uxr.json"
                        lottie-height="h-[220px]"
                        :title="$t('invoicing.invoice.send_modal.draft.title')"
                    >
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                prevent-default
                                @clicked="
                                    useEvent('invoicing:invoice:mark-as-sent:open', {
                                        invoice: invoice,
                                    })
                                "
                            >
                                {{ $t('invoicing.invoice.send_modal.draft.button') }}
                            </LoadingButton>
                        </div>
                    </ModalLottie>
                </div>
            </div>
            <ModalLoader v-if="loading" />
            <template v-else>
                <template v-if="error">
                    <ModalLottie
                        :title="$t('invoicing.invoice.send_modal.error.title')"
                        :subtitle="$t('invoicing.invoice.send_modal.error.text')"
                        json="https://shared-assets.trustup.io/lottie/error.json"
                    >
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:send-email:close')"
                            >
                                {{ $t('actions.close') }}
                            </LoadingButton>
                        </div>
                    </ModalLottie>
                </template>
                <template v-if="success">
                    <ModalLottie
                        :title="$t('invoicing.invoice.send_modal.success.title')"
                        :subtitle="
                            $t('invoicing.invoice.send_modal.success.text', {
                                to: displayableTo,
                            })
                        "
                    >
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:send-email:close')"
                            >
                                {{ $t('actions.close') }}
                            </LoadingButton>
                        </div>
                    </ModalLottie>
                </template>
                <template v-if="sending">
                    <ModalLottie
                        :title="$t('invoicing.invoice.send_modal.sending.title')"
                        json="https://shared-assets.trustup.io/lottie/send.json"
                    >
                    </ModalLottie>
                </template>
                <template v-if="!success && !sending && !error">
                    <form @submit.prevent="submit">
                        <div class="p-8">
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label class="flex items-center text-sm font-thin text-gray-800 whitespace-nowrap">
                                    {{ $t('form.labels.recipient') }}
                                </label>
                                <div class="justify-center">
                                    <FormEmailBadge
                                        :value="form.get('to')"
                                        :placeholder="$t('form.labels.recipient')"
                                        multiple
                                        @changed="(value) => form.set('to', value)"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="tenant?.isNotInTestMode"
                                class="flex items-center w-full py-2 space-x-4 border-b border-gray-200"
                            >
                                <label class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap">
                                    {{ $t('form.labels.cc') }}
                                    <span
                                        :data-tooltip="$t('form.helpers.cc')"
                                        data-tooltip-location="right"
                                        class="inline-block ml-1"
                                    >
                                        <i class="text-gray-400 fa-regular fa-question-circle hover:text-black"></i>
                                    </span>
                                </label>
                                <div class="flex-1">
                                    <FormEmailBadge
                                        multiple
                                        :value="form.get('cc')"
                                        :placeholder="$t('form.labels.cc')"
                                        @changed="(value) => form.set('cc', value)"
                                    />
                                </div>
                            </div>
                            <div class="flex items-center w-full py-4 space-x-4 border-b border-gray-200">
                                <label
                                    for="invoice-send-form-copy"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.copy') }}
                                    <span
                                        :data-tooltip="$t('form.helpers.copy', { email: tenant?.copyEmail })"
                                        data-tooltip-location="right"
                                        class="inline-block ml-1"
                                    >
                                        <i class="text-gray-400 fa-regular fa-question-circle hover:text-black"></i>
                                    </span>
                                </label>
                                <div class="flex-1">
                                    <input
                                        id="invoice-send-form-copy"
                                        name="copy"
                                        class="nice-checkbox"
                                        type="checkbox"
                                        :checked="form.get('copy')"
                                        @change="form.set('copy', $event.target.checked)"
                                    />
                                </div>
                            </div>
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label
                                    for="invoice-send-form-subject"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.email_subject') }}
                                </label>
                                <div class="flex-1">
                                    <input
                                        id="invoice-send-form-subject"
                                        name="subject"
                                        :value="form.get('subject')"
                                        class="w-full p-2 text-sm text-gray-600 outline-none focus:text-black"
                                        @change="form.set('subject', $event.target.value)"
                                    />
                                </div>
                            </div>
                            <div class="py-4">
                                <FormEditor
                                    :form="form"
                                    element-class-name="w-full"
                                    input-name="text"
                                    without-label
                                    class-name="w-full text-gray-600 text-sm font-thin focus:text-black outline-none h-[100px]"
                                >
                                    <template #after-input>
                                        <NuxtLink
                                            class="inline-block mt-1 text-xs text-gray-400 transition-all hover:text-gray-600 leading-right hover:underline"
                                            to="/settings/outbox/settings"
                                        >
                                            {{
                                                $t('invoicing.invoice.send_modal.reply_to_email_info', {
                                                    email: tenant?.replyToEmail ?? 'none',
                                                })
                                            }}
                                        </NuxtLink>
                                    </template>
                                </FormEditor>
                            </div>
                            <div class="py-4">
                                <InvoicingInvoiceModelFiles
                                    :invoice="invoice"
                                    with-upload
                                />
                            </div>
                            <div :class="$theme('modal.footer.button_container')">
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :class-name="$theme('button.style.cancel')"
                                    prevent-default
                                    @clicked="useEvent('invoicing:invoice:send-email:close')"
                                    >{{ $t('actions.cancel') }}
                                </LoadingButton>
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :class-name="$theme(isEmailValid ? 'button.style.default' : 'button.style.disabled')"
                                    :loading="form.isLoading"
                                    type="submit"
                                    :disabled="isEmailInvalid"
                                >
                                    <i class="mr-2 fa-solid fa-paper-plane"></i>
                                    {{ $t('actions.send') }}
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </template>
            </template>
        </div>
    </Modal>
</template>
