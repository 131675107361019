<script setup lang="ts">
    import InvoicingPaymentRemindersSetting from '@/app/invoicing/models/InvoicingPaymentRemindersSetting';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoicingPaymentRemindersSettingSchema from '~~/app/invoicing/schemas/InvoicingPaymentRemindersSettingSchema';
    import { useEvent } from '@/composables/useEventBus';
    import InvoicingPaymentRemindersSettingEndpoint from '~~/app/invoicing/endpoints/InvoicingPaymentRemindersSettingEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import InvoicePaymentReminderGroup from '~/app/invoicing/models/InvoicePaymentReminderGroup';
    import InvoicingPaymentRemindersSettingCollection from '~/app/invoicing/collections/InvoicingPaymentRemindersSettingCollection';

    interface Fields {
        level: number;
        enabled: boolean;
        dueDays: number;
        emailCopy: boolean;
        emailSubject: TranslatedField;
        emailText: TranslatedField;
        groupId: string;
    }
    const form = useForm<Fields>({
        level: 1,
        enabled: true,
        dueDays: 15,
        emailCopy: true,
        emailSubject: {},
        emailText: {},
        groupId: '',
    });
    form.setTranslatedFields(['emailSubject', 'emailText']);

    const { modalName, model } = useModelFormModal<InvoicingPaymentRemindersSetting, Fields>(
        InvoicingPaymentRemindersSetting,
        (payload?: FormModalPayload<InvoicingPaymentRemindersSetting, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const { loading, collection, load } = useCollectionLoader<InvoicingPaymentRemindersSetting, InvoicingPaymentRemindersSettingCollection>(ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS, [], () => {
        return {
            filter: {
                invoicePaymentReminderGroup: {
                    id: [group.value?.getId()],
                },
            },
        };
    });
    const defaultSettings = ref<InvoicingPaymentRemindersSettingCollection>();
    const endpoint = EndpointFactory.make(ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS);
    const group = ref<InvoicePaymentReminderGroup>();
    const fillForm = async (payload?: FormModalPayload<InvoicingPaymentRemindersSetting, { group: InvoicePaymentReminderGroup }>) => {
        form.load();
        await fetchDefault();
        if (payload?.model) {
            form.fillWithModel(payload?.model);
            form.set('groupId', payload.model?.invoicePaymentReminderGroup?.getId());
            group.value = payload.model?.invoicePaymentReminderGroup;
        }

        if (payload?.fields) {
            group.value = payload?.fields.group;
            form.set('groupId', group.value?.getId());

            await load();
            const settings = collection.value?.isEmpty() ? defaultSettings.value : collection.value;
            const setting = settings?.last();
            form.set('emailText', setting?.emailText);
            form.set('emailSubject', setting?.emailSubject);
            form.set('emailCopy', setting?.emailCopy);
            form.set('enabled', setting?.enabled);
            const dueDays = collection.value?.isEmpty() ? setting?.dueDays : (setting?.dueDays ?? 0) + 10;
            const level = collection.value?.isEmpty() ? setting?.level : (setting?.level ?? 0) + 1;
            form.set('dueDays', dueDays);
            form.set('level', level);
        }

        form.stop();
    };

    const fetchDefault = async () => {
        const response = await endpoint.index({ filter: { defaultGroup: true } });
        if (!response.data) return useToasteoError();
        defaultSettings.value = response.data;
    };

    const submit = async () => {
        const schema = InvoicingPaymentRemindersSettingSchema.make({ attributes: form.dataExcept(['groupId']) });
        schema.attributes!.emailCopy = !!form.get('emailCopy');
        if (model.value) {
            schema.id = model.value?.getId();
        }

        if (form.get('groupId')) {
            schema.addToOneRelationship('invoicePaymentReminderGroup', ModelType.INVOICE_PAYMENT_REMINDER_GROUPS, form.get('groupId'));
        }

        const endpoint: InvoicingPaymentRemindersSettingEndpoint = EndpointFactory.make(ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS) as InvoicingPaymentRemindersSettingEndpoint;
        endpoint.addInclude('invoicePaymentReminderGroup');
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="InvoicingPaymentRemindersSetting"
        scrollable
        @before-close="form.reset()"
    >
        <ModalLoader v-if="form.isLoading" />
        <div v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{
                        model
                            ? $t('invoicing.invoicing_payment_reminders_settings.edit_form.title', {
                                  name: group?.title,
                              })
                            : $t('invoicing.invoicing_payment_reminders_settings.create_form.title')
                    }}
                </h2>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <div class="flex items-center space-x-4">
                        <div class="w-[140px]">
                            <FormInput
                                :form="form"
                                input-name="level"
                                input-type="number"
                                disabled
                                autofocus
                                :label="$t('form.labels.number')"
                                :tooltip="$t('invoicing.invoicing_payment_reminders_settings.number_tooltip')"
                            />
                        </div>
                        <div class="w-[140px]">
                            <FormInput
                                :form="form"
                                input-name="dueDays"
                                input-type="number"
                                :label="$t('form.labels.reminder_delay')"
                                :tooltip="$t('invoicing.invoicing_payment_reminders_settings.due_days_tooltip')"
                            />
                        </div>
                        <div class="pt-5">
                            <FormCheckbox
                                :form="form"
                                input-name="enabled"
                            />
                        </div>
                    </div>
                    <template v-if="form.get('enabled')">
                        <FormInput
                            :form="form"
                            input-name="emailSubject"
                            translated
                            with-variables
                        />
                        <FormEditor
                            :form="form"
                            input-name="emailText"
                            translated
                        />
                        <FormCheckbox
                            :form="form"
                            input-name="emailCopy"
                            :label="$t('form.labels.copy')"
                        />
                    </template>
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.save') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </div>
    </ModelFormModal>
</template>
